import messages_en from './en.json';
import messages_fr from './fr.json';
import messages_global from './global.json';


const messages = {
    "fr": messages_fr,
    "en": messages_en,
    "global": messages_global
}

export default messages;